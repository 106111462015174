import type { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import { OfferLocation, OfferZone } from '@canalplus/sdk-core';
import classNames from 'classnames';
import { useRef } from 'react';
import { VideoType } from '../../constants/videoTypes';
import styles from './VideoFragment.module.css';
import { VideoPlayer } from './VideoPlayer';

export type VideoFragmentProps = {
  id: string;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  playerCredentials?: IAPICredentialsRaw;
  type?: VideoType;
};

export function VideoFragment({
  playerCredentials,
  type,
  id,
  offerLocation,
  offerZone,
}: VideoFragmentProps): JSX.Element {
  const DOM = useRef<HTMLDivElement | null>(null);
  return (
    <div className={classNames(styles.videoFragment)} ref={DOM}>
      <div className={styles.videoFragment__wrapper}>
        <div className={styles.videoFragment__player}>
          {(type === VideoType.Pfv || type === VideoType.Hapi) && (
            <VideoPlayer
              id={id}
              offerLocation={offerLocation}
              offerZone={offerZone}
              playerCredentials={playerCredentials}
              type={type}
              DOM={DOM}
            />
          )}
          {type === VideoType.Dailymotion && (
            /* eslint-disable react/iframe-missing-sandbox */
            <iframe
              className={classNames([
                styles['videoFragment__player--dailymotion'],
              ])}
              title="Player dailymotion"
              src={`//www.dailymotion.com/embed/video/${id}`}
            />
          )}
          {type === VideoType.Youtube && (
            /* eslint-disable react/iframe-missing-sandbox */
            <iframe
              className={classNames([styles['videoFragment__player--youtube']])}
              title="Player youtube"
              src={`//www.youtube.com/embed/${id}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

import { isSomeEnum } from '@canalplus/mycanal-commons';

export enum VideoType {
  Pfv = 'pfv',
  Hapi = 'hapi',
  Dailymotion = 'dailymotion',
  Youtube = 'youtube',
}

export const isVideoType = isSomeEnum(VideoType);

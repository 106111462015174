import type { I18nTranslationFunction } from '@canalplus/mycanal-i18n';
import { createContext, useContext } from 'react';

export type LangContextParams = {
  t: I18nTranslationFunction;
};

const LangContext = createContext<LangContextParams>({ t: (key) => key });

export function useTranslation(): { t: I18nTranslationFunction } {
  const { t } = useContext(LangContext);

  return { t };
}

export const { Provider: LangProvider } = LangContext;

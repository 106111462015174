import type { ReactNode } from 'react';
import styles from './TitleFragment.module.css';

export type TitleFragmentProps = {
  /** can be raw HTML content */
  title: string;
};

export function TitleFragment({ title }: TitleFragmentProps): ReactNode {
  return (
    <h2
      className={styles.TitleFragment}
      dangerouslySetInnerHTML={{ __html: title }} // eslint-disable-line react/no-danger
    />
  );
}

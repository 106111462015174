import type { ReactNode } from 'react';
import styles from './IntroductionFragment.module.css';

export type IntroductionFragmentProps = {
  text: string;
};

export function IntroductionFragment({
  text,
}: IntroductionFragmentProps): ReactNode {
  return <p className={styles.introductionFragment}>{text}</p>;
}

import {
  Media,
  MediaImage,
  THUMBOR_QUALITY_TO_DPR,
  ThumborQuality,
  getThumborSources,
  getThumborUrl,
  isDeviceToViewportToImageDimensions,
} from '@canalplus/dive';
import { DIMENSIONS, THUMBOR, VIEWPORTS } from '@canalplus/mycanal-commons';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import styles from './PictureFragment.module.css';

const cx = classNames.bind(styles);

export type PictureFragmentType = 'cover' | 'card';

export type PictureFragmentProps = {
  url: string;
  type?: PictureFragmentType;
  description?: string;
  qualityUserSettings: ThumborQuality;
  isImmersive?: boolean;
};

const dimensionTypeMapping = {
  'cover': DIMENSIONS.CREATIVEMEDIA_COVER,
  'card': DIMENSIONS.CREATIVEMEDIA_CARD,
};

export function PictureFragment({
  url,
  type,
  description,
  qualityUserSettings,
  isImmersive = false,
}: PictureFragmentProps): ReactNode {
  const isThumborReady = THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE.some(
    (endpoint) => url?.includes(endpoint)
  );
  const devicePixelRatio = THUMBOR_QUALITY_TO_DPR[qualityUserSettings];
  const dimension =
    (type && dimensionTypeMapping[type]) || DIMENSIONS.CREATIVEMEDIA_PICTURE;
  const dimensionsDefault = isDeviceToViewportToImageDimensions(dimension)
    ? dimension.default?.large
    : dimension?.large;

  const src = getThumborUrl({
    url,
    devicePixelRatio,
    includesAllowedSourceInUrl: isThumborReady,
    quality: qualityUserSettings,
    dimensions: dimensionsDefault,
  });

  const sources = getThumborSources({
    urls: { default: url },
    dimensions: dimension,
    devicePixelRatio,
    viewports: VIEWPORTS.ASCENDING,
    includesAllowedSourceInUrl: isThumborReady,
    quality: qualityUserSettings,
  });

  if (type === 'card') {
    return (
      <Media
        aria-hidden={!description}
        aria-label={description}
        aspectRatio="43"
        className={cx('PictureFragment__card', {
          'PictureFragment__card--immersive': isImmersive,
        })}
        image={
          <MediaImage
            alt={description}
            src={src.url}
            sources={sources}
            height="100%"
            width="auto"
          />
        }
      />
    );
  }

  if (type === 'cover') {
    return (
      <MediaImage
        src={src.url}
        sources={sources}
        height="100%"
        width="auto"
        alt={description}
      />
    );
  }

  return (
    <div className={cx('PictureFragment')}>
      <MediaImage
        src={src.url}
        sources={sources}
        alt={description}
        aria-hidden={!description}
      />
    </div>
  );
}

import type { ReactNode } from 'react';
import styles from './RawFragment.module.css';

export type RawFragmentProps = {
  /** can be raw HTML content */
  text: string;
};

const RAW_FRAGMENT_CTA_CLASSNAME = '.cta';

export function overrideCtaStyle(text: string): string {
  return text.replace(
    /<style[^>]*>[\s\S]*?<\/style>/gi,
    `<style type="text/css">

  .cta { 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0 auto; 
    width: fit-content;
    min-height: 2.75rem;
    @media (min-width: 700px) {
      margin: var(--dt-spacing-none) var(--dt-spacing-1600);
    }
    @media (min-width: 960px) {
      min-height: 3rem;
    }
    border: none; 
    border-radius: var(--dt-radius-medium); 
    background-color: var(--dt-theme-icon-button-primary-icon-accent);
  } 

  .cta:hover{
    background-color: var(--dt-theme-icon-button-primary-icon-accent-hover);
  }

  .cta a { 
    display: inline-block; 
    padding: var(--dt-spacing-none) var(--dt-spacing-200); 
    color: var(--dt-theme-text-button-primary-primary); 
    font-family: var(--dt-font-family-brand); 
    font-size: var(--dt-font-size-16); 
    line-height: var(--dt-font-line-height-20); 
    text-align: center; 
    text-decoration: none; 
    text-transform: uppercase; 
  } 

  .cta a > * {
    font-family: inherit;
    text-decoration: inherit; 
    color: inherit !important; 
  }

 </style>`
  );
}

export function RawFragment({ text }: RawFragmentProps): ReactNode {
  return (
    <div
      /* eslint-disable-line react/no-danger */ dangerouslySetInnerHTML={{
        __html: text.includes(RAW_FRAGMENT_CTA_CLASSNAME)
          ? overrideCtaStyle(text)
          : text,
      }}
      className={styles.RawFragment}
    />
  );
}

import classNames from 'classnames';
import type { ReactNode } from 'react';
import styles from './MainTitleFragment.module.css';

export type MainTitleFragmentProps = {
  /** can be raw HTML content */
  title: string;
};

export function MainTitleFragment({
  title,
}: MainTitleFragmentProps): ReactNode {
  return (
    <h1
      id="immersive-title"
      className={classNames(styles.MainTitleFragment)}
      dangerouslySetInnerHTML={{ __html: title }} // eslint-disable-line react/no-danger
    />
  );
}

import { injectScriptToElement } from '@canalplus/mycanal-commons';
import { useEffect, useRef } from 'react';

export type AdvertisingFragmentProps = {
  html: string;
  css: string;
  js?: string;
};

export function AdvertisingFragment({
  html,
  css,
  js = '',
}: AdvertisingFragmentProps): JSX.Element {
  const scriptContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (js.trim() && scriptContainerRef?.current) {
      injectScriptToElement(js, scriptContainerRef.current);
    }
  }, [js]);

  return (
    <div data-testid="advertising__container">
      <div ref={scriptContainerRef} style={{ visibility: 'hidden' }} />
      {/* eslint-disable react/no-danger */}
      <div
        data-testid="advertising__style"
        dangerouslySetInnerHTML={{ __html: css }}
      />
      <div
        data-testid="advertising__html"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      {}
    </div>
  );
}

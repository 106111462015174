import type { ReactNode } from 'react';
import styles from './IFrameFragment.module.css';

export type IFrameFragmentProps = {
  url?: string;
  height?: string;
};

export function IFrameFragment({
  url,
  height,
}: IFrameFragmentProps): ReactNode {
  if (!url) {
    return null;
  }

  return (
    /* eslint-disable react/iframe-missing-sandbox */
    <iframe
      className={styles.IFrameFragment}
      height={height}
      src={url}
      title="iframe"
    />
  );
}

import type { ReactNode } from 'react';
import styles from './QuoteFragment.module.css';

export type QuoteFragmentProps = {
  /** can be raw HTML content */
  text: string;
};

export function QuoteFragment({ text }: QuoteFragmentProps): ReactNode {
  return (
    <div className={styles.QuoteFragment__container}>
      <q
        className={styles.QuoteFragment}
        /* eslint-disable-line react/no-danger */ dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  );
}

import { type ReactNode, useEffect } from 'react';
import styles from './TwitterFragment.module.css';

export type TwitterFragmentProps = {
  text: string;
};

export function TwitterFragment({ text }: TwitterFragmentProps): ReactNode {
  useEffect(() => {
    const twitterScript = document.createElement('script');
    twitterScript.src = 'https://platform.twitter.com/widgets.js';
    document
      .getElementsByClassName('twitter-tweet')[0]
      ?.appendChild(twitterScript);
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: text }} // eslint-disable-line react/no-danger
      className={styles.twitterFragment}
    />
  );
}

import { decodeHtmlCharCodes } from './decodeHTML';

/**
 * Extract and parse JSON from the string text and the HTML tag
 *
 * @example
 * const json = extractAndParseJSON('<p>{ "name": "John", "age": 30 }</p>');
 * console.log(json); // { name: 'John', age: 30 }
 */
export const extractAndParseJSON = (text: string): any => {
  // Regular expression to match JSON content surrounded by exactly one HTML tag
  const tagMatch = text.match(/^<(\w+)>\s*({[\s\S]*?})\s*<\/\1>$/);

  if (tagMatch && tagMatch[2]) {
    // Extract the JSON string and clean up extra whitespace inside the JSON
    const decodeString = decodeHtmlCharCodes(tagMatch[2]);
    const jsonString = decodeString.trim(); // Trim to remove leading and trailing whitespace

    try {
      // Parse the JSON string into an object
      return JSON.parse(jsonString);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};
